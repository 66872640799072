<template>
  <div class="container py-4 py-lg-5">
    <LottieBackground
      :preferredNo="1"
    />

    <div class="row">
      <div class="col">
        <b-button variant="primary" :to="{ name: 'home' }" class="mb-2 mb-lg-3">
          <font-awesome-icon icon="angle-left" fixed-width/>
          {{ $t("context.overview") }}
        </b-button>
      </div>
    </div>
    <div class="row">
      <div class="col col-sm-12 col-md-6">
        <div class="card">
          <div class="card-body" v-if="student">
            <h1 class="h2">{{ decodeHTML($t('context.child_welcome', { name: student.Name })) }}</h1>
            <p class="mb-0">{{ $t('context.child_welcome_intro') }}</p>

            <div class="float-right">
              <TheMascotte
                :preferredNo="1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5" id="childhome-stickers" v-if="student && student.ActivityGroup.length">
      <div
        v-for="(group, index) in uniqueGroups"
        class="col col-6 col-sm-3 sticker-wrap"
        :key="'group_'+group.ActivityGroupID"
      >
        <router-link
          :to="{ name: 'stickerbook', params: { student_id: student.PersonID, group_id: group.ActivityGroupID } }"
          class="sticker big animate__animated animate__bounceIn"
          :class="`animation-delay-${index}`"
        >
          <img :src="group.SkillCategory.Image" class="image completed" v-if="student && group && group.SkillCategory.Image"/>

          <div class="activitygroupname">{{ has(group, 'SkillCategory.Name') ? group.SkillCategory.Name : group.Name }}</div>
        </router-link>
      </div>
    </div>
    <div v-else>
      <h3 class="mt-5">{{ $t('context.no_stickerbooks_found_yet') }}</h3>
    </div>
  </div>
</template>

<script>
import { has, uniqBy } from 'lodash'
import TheMascotte from '@/components/TheMascotte'
import LottieBackground from '@/components/LottieBackground'
import initialsMixin from '@/util/initialsMixin'
import he from 'he'

export default {
  name: 'ChildHome',

  mixins: [initialsMixin],

  components: {
    TheMascotte,
    LottieBackground
  },

  data () {
    return {
      has
    }
  },

  computed: {
    student () {
      return this.$store.state.persons.persons.find(person => person.PersonID === this.$route.params.student_id)
    },

    uniqueGroups () {
      return uniqBy(this.student.ActivityGroup, 'SkillCategoryID')
    }
  },

  methods: {
    decodeHTML (text) {
      return he.decode(text)
    }
  }
}
</script>
