<template>
  <div class="container py-3 py-md-4 py-lg-5">
    <header class="mb-4 mb-lg-5 row text-contrast">
      <div class="col-12 col-md flex-grow-0 order-md-12 sentry-unmask" style="min-width: auto" v-if="!isCustomerPortal">
        <DatepickerToolbar/>
      </div>

      <div class="col">
        <h1 class="display-4 w-50" :class="userType === 'Customer' ? 'greeting' : ''">{{ greeting }}</h1>
      </div>
    </header>

    <transition name="fade" mode="out-in">
      <div v-if="!userType">
        <p class="text-contrast"><em>{{ $t("context.data_retrieve") }}</em></p>
      </div>

      <div v-else-if="userType === 'User'">
        <section class="mb-4 mb-lg-5">
          <h2 class="text-contrast mb-3">
            <font-awesome-icon class="mr-2" icon="users" fixed-width/>

            <span v-if="activeView">{{ activeViewName }}</span>
            <span v-else-if="activeEmployee">{{`Lesgroepen van ${activeEmployee.Name}`}}</span>
            <span v-else>{{ 'Jouw lesgroepen' }}</span>
            <transition name="fade">
              <font-awesome-icon class="ml-3" v-if="$store.state.groups.loading" spin icon="rotate"/>
            </transition>
          </h2>

          <transition name="fade" mode="out-in">
            <div v-if="Object.keys(groupsByHour).length > 0 && !$store.state.groups.loading" :key="'groupsByHour'">
              <div class="row mb-2 mb-md-3" v-for="(groups, hour) in groupsByHour" :key="`${$store.getters['groups/viewDate']}-${hour}`">
                <div class="col-12">
                  <h4 class="text-contrast">
                    <font-awesome-icon class="mr-2" :icon="['fad', 'clock']" fixed-width/>
                    <span>{{ hour }}</span>
                  </h4>
                </div>
                <group-list-item :key="group.ActivityGroupID" :group="group" v-for="group in groups"></group-list-item>
              </div>
            </div>

            <div class="row" v-else-if="ownGroups.length > 0 && !$store.state.groups.loading" :key="'ownGroups'">
              <group-list-item :key="group.ActivityGroupID" :group="group" v-for="group in ownGroups"></group-list-item>
            </div>

            <p class="text-contrast" v-else-if="$store.state.groups.loading" :key="'loading'"><em>{{ $t("context.group_retrieve") }}</em></p>

            <p class="text-contrast" v-else :key="'not-found'"><font-awesome-icon icon="magnifying-glass" size="lg" class="mr-2" fixed-width /> <em>{{ $t("context.no_group_found") }}</em></p>
          </transition>
        </section>

        <transition name="fade" mode="out-in">
          <section class="mb-4 mb-lg-5" v-if="tempGroups.length > 0 && !$store.state.groups.loading">
            <h2 class="text-contrast mb-3">
              <font-awesome-icon class="mr-2" icon="users"/>{{ activeEmployee ? $t('context.substitute_group_of') + ` ${activeEmployee.Name}` : $t('context.substitute_group') }}
            </h2>

            <div class="row">
              <group-list-item temp :key="group.ActivityGroupID" :group="group" v-for="group in tempGroups"></group-list-item>
            </div>
          </section>
        </transition>
      </div>

      <div v-else-if="userType === 'Customer'">
        <section class="mb-4 mb-lg-5">
          <h2 class="text-contrast mb-3">
            <font-awesome-icon class="mr-2" icon="user"/>
            {{ $t("context.students") }}
            <transition name="fade">
              <font-awesome-icon class="ml-3" v-if="$store.state.persons.loading" spin icon="rotate"/>
            </transition>
          </h2>

          <transition name="fade" mode="out-in">
            <div class="row d-flex" v-if="persons.length > 0 && !$store.state.persons.loading">
              <person-list-item  class="" :key="person.PersonID" :person="person" v-for="person in persons"></person-list-item>
            </div>

            <p class="text-contrast" v-else-if="$store.state.persons.loading"><em>{{ $t("context.students_retrieve") }}</em></p>
            <p class="text-contrast" v-else><font-awesome-icon icon="magnifying-glass" size="lg" class="mr-2" fixed-width /> <em>{{ $t("context.no_students_found") }}</em></p>
          </transition>
        </section>
      </div>
    </transition>
  </div>
</template>

<script>
import GroupListItem from '../components/GroupListItem'
import PersonListItem from '../components/PersonListItem'
import DatepickerToolbar from '../components/DatepickerToolbar'

export default {
  name: 'home',
  components: { DatepickerToolbar, PersonListItem, GroupListItem },
  computed: {
    userType () {
      return this.$store.getters['auth/userType']
    },
    greeting () {
      if (!this.$store.getters['auth/username']) {
        return this.$t('context.greeting') + `!`
      }
      return this.$t('context.greeting') + ` ${this.$store.getters['auth/username']}!`
    },
    persons () {
      return this.$store.getters['persons/own']
    },
    ownGroups () {
      return this.$store.getters['groups/own'].filter(group => {
        return !this.activeView || this.$store.getters['warehouses/all'].length <= 1 || this.$store.state.warehouses.viewWarehouses.includes(group.WarehouseID)
      })
    },
    tempGroups () {
      return this.$store.getters['groups/temp']
    },
    groupsByHour () {
      if (!this.activeView) {
        return {}
      }

      const groupsByHour = this.ownGroups
        .reduce((groups, group) => {
          let h = (new Date(group.StartDate)).toLocaleTimeString('nl', {
            // timeStyle: 'short',
            hour: 'numeric',
            minute: '2-digit'
          }) + ' uur'
          groups[h] = groups[h] || []
          groups[h].push(group)
          return groups
        }, {})

      const ordered = {}

      const sortedKeys = Object.keys(groupsByHour).sort((a, b) => {
        const startA = new Date(groupsByHour[a][0].StartDate)
        const startB = new Date(groupsByHour[b][0].StartDate)
        if (startA < startB) return -1
        if (startA > startB) return 1
        return 0
      })

      sortedKeys.forEach(key => {
        ordered[key] = groupsByHour[key].sort((a, b) => {
          if (a.SkillCategoryID < b.SkillCategoryID) return -1
          if (a.SkillCategoryID > b.SkillCategoryID) return 1
          if (a.Name < b.Name) return -1
          if (a.Name > b.Name) return 1
          return 0
        })
      })

      return ordered
    },
    activeEmployee () {
      return this.$store.getters['groups/activeEmployee']
    },
    activeView () {
      return this.$store.getters['groups/activeView']
    },
    activeViewName () {
      return `Lessen op ${this.$store.getters['groups/viewDate'].toLocaleDateString('nl', {
        dateStyle: 'full'
      })}`
    }
  },
  methods: {
    fetch () {
      if (this.userType === 'Customer') {
        this.$store.dispatch('persons/fetch')
      } else if (this.userType === 'User') {
        this.$store.dispatch('groups/fetch')
      }
    }
  },
  mounted () {
    this.fetch()
  },

  watch: {
    userType (userType) {
      if (userType === 'Customer') {
        this.$store.dispatch('persons/fetch')
      } else if (userType === 'User') {
        this.$store.dispatch('groups/fetch')
      }
    },

    '$route' (newVal) {
      // When coming back home refresh the data.
      if (newVal.name === 'home') {
        this.fetch()
      }
    }
  }
}
</script>
