<template>
  <!-- Modal Component -->
  <b-modal
    :id="_id('modal-transfer')"
    v-model="show"
    @ok="startTransfer"
    @show="fetchSimilarGroups"
    ok-variant="success"
    :ok-title="$t('context.transfer')"
    cancel-variant="outline-dark"
    :cancel-title="$t('actions.cancel')"
    @hidden="$emit('transferModalClosed')"
    :ok-disabled="isNil(transferToGroup)"
  >
    <span slot="modal-title"><font-awesome-icon icon="angles-right" fixed-width/>{{ $t('context.transfer_student', { count: student || multipleStudents.length === 1 ? 1 : 2 }) }}</span>

    <b-form-group :label="$t('context.transfer_skillcategory_student', { count: student || multipleStudents.length === 1 ? 1 : 2 })" :description="!$store.getters['auth/isAdmin'] ? $t('context.transfer_group_student_error') : ''">
      <b-select v-model="transferToSkillCategory" value-field="SkillCategoryID" text-field="Name" :disabled="transferGroupOptions.length === 0">
        <option :value="null">{{ $t('context.without_skill_categories') }}</option>
        <optgroup v-for="activity in groupedSkillCategories" :key="activity.ActivityID" :label="activity.ActivityName">
          <option v-for="category in activity.categories" :key="category.SkillCategoryID" :value="category.SkillCategoryID">
            {{ category.Name }}
          </option>
        </optgroup>
      </b-select>
    </b-form-group>

    <b-form-group :label="$t('context.transfer_group_student', { count: student || multipleStudents.length === 1 ? 1 : 2 })" :description="!$store.getters['auth/isAdmin'] ? $t('context.transfer_group_student_error') : ''">
      <b-select v-model="transferToGroup" :disabled="transferGroupOptions.length === 0" value-field="ActivityGroupID" text-field="Name" :options="transferGroupOptions"></b-select>
    </b-form-group>

    <b-form-group :label="$t('context.transfer_which_date')">
      <b-form-datepicker
        locale="nl-NL"
        hide-header
        :min="new Date().toISOString().split('T')[0]"
        :max="maxDate" required today-button
        v-model="transferToGroupStartDate"
        :disabled="transferGroupOptions.length === 0"
        v-bind="{
            labelPrevDecade: $t('datepicker.decade_back'),
            labelPrevYear: $t('datepicker.year_back'),
            labelPrevMonth: $t('datepicker.month_back'),
            labelCurrentMonth: $t('datepicker.this_month'),
            labelNextMonth: $t('datepicker.month_ahead'),
            labelNextYear: $t('datepicker.year_ahead'),
            labelNextDecade: $t('datepicker.decade_ahead'),
            labelToday: $t('common.today'),
            labelTodayButton: $t('datepicker.select_today'),
            labelSelected: $t('datepicker.chosen_date'),
            labelNoDateSelected: $t('datepicker.no_date_chosen'),
            labelCalendar: $t('common.calendar'),
            labelNav: $t('common.navigation'),
            labelHelp: $t('datepicker.use_arrows_to_navigate')
        }"
      />
    </b-form-group>

    <b-checkbox v-model="adoptSkillCategoryFromGroup">{{ $t('context.select_skill_from_new_group') }}</b-checkbox>
  </b-modal>
</template>

<script>
import { studentTracking } from '@/http'
import { isNil } from 'lodash'

export default {
  name: 'StudentTransferModal',

  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    student: {
      type: Object,
      required: false
    },
    // This is an array of students personIds, if we want to transfer multiple students at once (overview page).
    multipleStudents: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  data () {
    return {
      isNil,
      show: false,
      transferToGroup: null,
      similarGroups: [],
      loading: false,
      transferring: false,
      transferToGroupStartDate: new Date().toISOString().split('T')[0],
      maxDate: (() => {
        const d = new Date()
        d.setUTCMonth(d.getUTCMonth() + 2)
        return d.toISOString().split('T')[0]
      })(),
      adoptSkillCategoryFromGroup: true,
      transferToSkillCategory: null,
      similarSkillCategories: []
    }
  },

  computed: {
    transferGroupOptions () {
      if (this.transferToSkillCategory) {
        return this.similarGroups.filter((x) => {
          return x.SkillCategoryID === this.transferToSkillCategory
        })
      }

      return this.similarGroups
    },

    groupedSkillCategories () {
      const grouped = {
        noActivity: {
          ActivityID: 'noActivity',
          ActivityName: 'Zonder activiteit',
          categories: []
        }
      }

      this.similarSkillCategories.forEach(category => {
        if (!category.Activity) {
          grouped.noActivity.categories.push(category)
          return
        }

        const activityId = category.Activity.ActivityID
        if (!grouped[activityId]) {
          grouped[activityId] = {
            ActivityID: activityId,
            ActivityName: category.Activity.Name,
            categories: []
          }
        }
        grouped[activityId].categories.push(category)
      })

      // Filter out noActivity if it has no categories, then sort remaining groups
      const result = Object.values(grouped)
        .filter(group => {
          if (group.ActivityID === 'noActivity') {
            return group.categories.length > 0
          }
          return true
        })
        .sort((a, b) => {
          if (a.ActivityID === 'noActivity') return 1
          if (b.ActivityID === 'noActivity') return -1
          return a.ActivityName.localeCompare(b.ActivityName)
        })

      return result
    }
  },

  methods: {
    startTransfer () {
      this.transferring = true

      if (!this.$route.params.agenda_id) {
        console.error('No agenda_id found in route!')
        this.showErrorToast({ message: this.$t('common.error') })
        this.transferring = false
        return
      }

      // We transfer one or multiple students at once, depending omn the multipleStudents prop.
      this.transfer(this.multipleStudents.length ? this.multipleStudents : [this.student])
    },

    transfer (persons) {
      persons.forEach((person, index) => {
        studentTracking.put(`subscription-agenda/${this.$route.params.agenda_id}/persons/${person.PersonID}`, {
          ActivityGroupID: this.transferToGroup,
          StartDate: this.transferToGroupStartDate,
          AdoptSkillCategoryFromGroupYN: this.adoptSkillCategoryFromGroup
        }).then(() => {
          // Check if this is the last in the loop before we redirect and show a toast.
          if ((index + 1) === this.multipleStudents.length || this.student) {
            this.$emit('fetchHistory')
            this.$emit('markGroupForRefresh', { group: this.$route.params.agenda_id })

            this.show = false

            // Show different toast messages depending on the amount of students transferred.
            if (this.multipleStudents.length === 1 || this.student) {
              this.showSuccessToast(this.$t('context.student_succesfull_moved', { 'person': person.Name }))
            } else {
              this.showSuccessToast(this.$t('context.students_succesfull_moved'))
            }

            if (this.$route.name === 'group') {
              this.$store.dispatch('groups/setViewDate', new Date(this.transferToGroupStartDate))
              this.$router.push({ name: 'home' })
            } else {
              this.$router.push({ name: 'group' })
            }
          }
        }).catch(err => {
          this.showErrorToast(err)
        }).finally(() => {
          this.transferring = false
        })
      })
    },

    fetchSimilarGroups () {
      this.loading = true

      studentTracking.get(`subscription-agenda/${this.$route.params.agenda_id || 'current'}/similar`)
        .then(response => {
          this.loading = false

          this.similarGroups = response.data.items ? response.data.items.sort((a, b) => a.Name.localeCompare(b.Name)) : []
          this.similarSkillCategories = response.data.SkillCategories
            ? response.data.SkillCategories.sort((a, b) => a.Name.localeCompare(b.Name)) : []

          // Pre set the skill category to the first one in the list.
          this.transferToSkillCategory = this.student?.SkillCategoryID

          if (this.student && this.student.ActivityGroup.find((x) => x.SkillCategoryID === this.student.SkillCategoryID)) {
            this.transferToGroup = this.student.ActivityGroup.find((x) => x.SkillCategoryID === this.student.SkillCategoryID)['ActivityGroupID']
          }
        })
    }
  },

  watch: {
    // Reset everything.
    showModal (val) {
      this.show = val

      if (val) {
        this.transferToGroup = null
        this.similarGroups = []
        this.transferring = false
        this.adoptSkillCategoryFromGroup = true
        this.transferToSkillCategory = null
      }
    },

    transferToSkillCategory (newVal) {
      if (!isNil(newVal)) {
        this.transferToGroup = null
      }
    },

    transferring (bool) {
      this.$emit('setTransferring', bool)
    }
  }
}
</script>
